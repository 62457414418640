@import "@Common/Style/colors.scss";

.lesson-detail-container {
    height: 100vh;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    .header {
        display: flex;
    }
    .list-words {
        border: 1px solid;
        border-radius: 5px;
        padding: 10px;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        overflow-x: scroll;
        .word {
            border-bottom: 1px solid;
            margin-bottom: 10px;
            &:hover {
                cursor: pointer;
            }
            // &:nth-last-child(1){
            //     border-bottom: none;
            // }
        }
    }
    .action {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
    }
}
