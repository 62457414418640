@import "@Common/Style/colors.scss";

.word-content {
    text-align: center;
}

.header {
    position: relative;
}

.card {
    // border: 3px solid;
    // border-radius: 10px;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.btn-practice {
    margin: 20px 0px;
    width: 100%;
    background-color: $green !important;
    color: $white !important;
}

.front-card{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.back-card {
    .mean {
        padding: 10px;
    }
    .example-list {
        padding: 10px;
    }
}

.pronunciation {
    margin: 20px 0;
    display: flex;
    justify-content: space-around;
    .action {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }
}
