.MuiMobileStepper-progress{
    width: 100%;
}

.exam-stepper-container{
    margin: 10px 0;
    display: flex;
    background-color: #c3c3c3;
    padding: 10px;
    border-radius: 10px;
    .question-count{
        margin: 0 5px;
        padding: 5px 10px;
        &:hover{
            cursor: pointer;
        }
    }
    .active-question{
        border: 2px solid #03A9F4;
    }
    .correct-answer{
        background-color: #03A9F4;
        color: white;
    }
    .incorrect-answer{
        background-color: red;
        color: white;
    }
    
}

