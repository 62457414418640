.question-container {
    margin-bottom: 20px;
    .answer-part {
        display: flex;
        flex-direction: column;
        .answer {
            text-transform: none;
            justify-content: start;
            border-radius: 5px;
            border: 2px solid #c3c3c3;
            padding: 10px;
            margin: 10px 0;
            &:hover {
                cursor: pointer;
            }
        }
        .answer-select {
            border-color: #0000ff96;
            color: #0000ff96;
        }
        .correct-answer {
            border-color: green;
            color: green;
        }
        .incorrect-answer {
            border-color: red;
            color: red;
        }
    }
    .back-next-button-container {
        display: flex;
        justify-content: space-between;
    }
}
