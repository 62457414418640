@import "@Common/Style/colors.scss";
.test-lesson-container{
    .header{
        background-color: $green;
        align-items: center;
        position: relative;
        padding: 10px 0;
        .text{
            color: $white;
            font-size: 20px;
            font-weight: bold;
            margin-left: -32px;
            text-align: center;
            width: 100%;
            // position: absolute;
            // right: 50%;
            // transform: translateX(50%);
            
        }
    }
    .body{
        padding: 0 10px;
        text-align: center;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        overflow-x: scroll;
        .btn-test {
            background-color: $orange;
            color: $white
        }
    }
    .footer{
        padding: 0 10px;
        .btn-next{
            width: 100%;
            background-color: $green;
            color: $white
        }
    }
}