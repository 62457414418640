@import "@Common/Style/mixin.scss";

.app-learn-container {
    @include Responsive(XS) {
        width: 100%;
    }
    @include Responsive(SM) {
        width: 50%;
        margin: 0 auto;
    }
}

.global-padding{
    padding: 0 10px
}

.header {
    display: flex 
}
