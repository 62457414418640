@import "@Common/Style/colors.scss";

.app-learn-container{
    height: 100vh;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.list-lessons-container {
    height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        overflow-x: scroll;
    .lesson {
        position: relative;
        margin: 10px 0;
        padding: 15px;
        border-radius: 10px;
        border: 2px solid #cbc7c7;
        display: flex;
        justify-content: space-between;
        &:hover {
            cursor: pointer;
            box-shadow: 0 0 2px 0px;
        }
    }
    .doing {
        background-color: $white;
    }
    .done {
        background-color: $green;
    }
}
